import axios from '~/lib/utils/axios_utils';
import { s__ } from '~/locale';
import { buildApiUrl } from '~/api/api_utils';

export const verificationCodePath = '/-/sms/verification_code';

export const getVerificationCode = (params) => {
  const url = buildApiUrl(verificationCodePath);
  return axios
    .post(url, params)
    .then(({ data }) => {
      let message;
      switch (data.status) {
        case 'SENDING_LIMIT_RATE_ERROR': {
          message = s__('RealName|You can not send more verification code within one minute');
          break;
        }
        default: {
          if (data.status !== 'OK') {
            message = s__('RealName|An error occurred while sending verification code');
          }
          break;
        }
      }
      if (message) {
        throw new Error(message);
      }
    })
    .catch(() => {
      throw new Error(s__('RealName|An error occurred while sending verification code'));
    });
};
